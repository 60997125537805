<template>
  <div>
    <v-card @click="navigate" v-if="recipe" class="fill-height" outlined hover style="maxHeight: 165px; minHeight: 165px">
      <router-link :to="!recipe.isCustomized && (recipe.user == user.id || view == 'my' || user.role == 'admin') ? `/recipes/${recipe.id}` : '#'">
        <v-card-text class="pa-2">
          <div dense>
            <div class="d-flex gap-10">
              <v-card max-width="100" max-height="100" outlined tile>
                <recipe-image :recipe="recipe" size="medium" />
                <todos-chip
                  @showRecipe="showRecipeCard(recipe)"
                  :recipe="recipe"
                />
              </v-card>

              <v-row>
                <v-col cols="9" style="paddingRight: 0">
                  <div
                    :class="(recipe.isCustomized && view != 'my') ? 'blue--text' : 'primary--text'"
                    class="subtitle-1 font-weight-bold"
                    :style="{ lineHeight: '1.4' }"
                  >
                    {{ recipe.name }}
                  </div>
                  <v-chip
                    color="orange white--text"
                    x-small
                    v-if="recipe.isCustomized && view != 'my'"
                  >
                    Custom
                  </v-chip>
                  <div class="grey--text" v-if="user.energyUnit == 'cal'">
                    {{
                      recipe.servingValues.energy
                        | kjToCal
                        | amount
                        | numberFormat
                    }}
                    Cal
                  </div>
                  <div class="grey--text" v-else>
                    {{ recipe.servingValues.energy | amount | numberFormat }} kJ
                  </div>
                  <recipe-rating v-if="!recipe.isCustomized || view == 'my'" :recipe="recipe" />

                </v-col>
                <v-col cols="2" style="paddingX: 0">
                  <!-- save icon -->
                  <div class="d-flex">
                    <v-btn
                      v-show="!recipe.isCustomized && (view == 'all' || view == 'saved') && user.role != 'admin'"
                      v-if="savedRecipes.includes(recipe.id)"
                      @click="unsave({ user, recipe })"
                      :loading="loading"
                      icon
                      small
                      class="float-right" 
                    >
                      <v-icon v-if="user.role == 'member'" color="error" small>mdi-heart</v-icon>
                      <v-icon v-else color="warning" small>mdi-bookmark</v-icon>
                    </v-btn>
                    <v-btn
                      v-show="!recipe.isCustomized && view == 'all' && user.role != 'admin'"
                      v-else
                      @click="save({ user, recipe })"
                      :loading="loading"
                      icon
                      small
                      class="float-right" 
                    >
                      <v-icon v-if="user.role == 'member'" small>mdi-heart-outline</v-icon>
                      <v-icon v-else small>mdi-bookmark-outline</v-icon>
                    </v-btn>
                    <div
                      v-if="(!recipe.isCustomized && recipe.user == user.id) || view == 'my' || user.role == 'admin'"
                      class="float-right recipe-actions"
                    >
                      <v-menu bottom right offset-y transition="slide-x-transition"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn @click.prevent v-on="on" icon small>
                            <v-icon small>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list min-width="120">
                          <v-list-item dense @click="showRecipeCard(recipe)">
                            <v-list-item-icon class="mr-2"
                              ><v-icon small
                                >mdi-card-bulleted-outline</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-title>View recipe card</v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            dense
                            :to="{ name: 'EditRecipe', params: { id: recipe.id } }"
                          >
                            <v-list-item-icon class="mr-2"
                              ><v-icon small>mdi-pencil</v-icon></v-list-item-icon
                            >
                            <v-list-item-title>Edit</v-list-item-title>
                          </v-list-item>

                          <v-list-item @click="duplicateRecipe(recipe)" dense>
                            <v-list-item-icon class="mr-2"
                              ><v-icon small
                                >mdi-content-copy</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-title>Duplicate</v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            v-if="
                              user.role == 'admin' && recipe.status == 'published'
                            "
                            @click="updateToPending(recipe)"
                            dense
                          >
                            <v-list-item-icon class="mr-2"
                              ><v-icon small
                                >mdi-arrow-u-down-left</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-title>Move to pending</v-list-item-title>
                          </v-list-item>

                          <v-divider></v-divider>

                          <v-list-item dense @click="setDeleteData(recipe)">
                            <v-list-item-icon class="mr-2"
                              ><v-icon color="red" small
                                >mdi-delete</v-icon
                              ></v-list-item-icon
                            >
                            <v-list-item-title color="red">
                              <span class="red--text">Delete</span>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </router-link>
     
      <v-card-actions class="mb-3">
        <v-btn
          @click="openRecipe(recipe)"
          :color="!recipe.isCustomized  ? 'primary' : 'info'"
          v-if="user.role === 'admin'"
          class="mr-1"
          x-small
          depressed
          >Open</v-btn
        >

        <v-btn
          @click="openRecipe(recipe)"
          :color="(!recipe.isCustomized || view == 'my') ? 'primary' : 'info'"
          v-if="(!recipe.isCustomized && recipe.user == user.id) || view == 'my'"
          v-show="user.role !== 'admin'"
          class="mr-1"
          x-small
          depressed
          >Open</v-btn
        >

        <v-btn
          @click="showRecipeCard(recipe)"
          :color="(recipe.isCustomized && view != 'my') ? 'info' : 'primary'"
          outlined
          x-small
          text
          >View Card</v-btn
        >
      </v-card-actions>
    </v-card>
    <ConfirmDelete
      :showDialog="deleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="deleteDialog = false"
      @confirmed="deleteConfirmed()"
    />

    <RecipeCard
      v-if="dialogRecipe"
      :recipe="dialogRecipe"
      :show="showCard"
      @cancel="closeRecipeCard()"
    />

    <CustomRecipeCard
      :recipe="dialogRecipe"
      :show="showCustomCard"
      @cancel="() => closeCustomRecipeCard()"
    />

    <!-- ALL RECIPES IDs -->
    <AllRecipeIdsDialog ref="allrecipedialog" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import RecipeImage from '@/components/RecipeImage';
import TodosChip from '@/views/recipe/components/TodosChip';

import RecipeRating from '@/components/RecipeRating';
import RecipeCard from '../../recipe/components/RecipeCard';
import CustomRecipeCard from '../../recipe/components/CustomRecipeCard.vue';
import AllRecipeIdsDialog from '@/components/AllRecipeIdsDialog.vue';

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    recipe: {
      type: [Object, null],
    },
    cell: {
      type: [Object, null],
    },
    view: {
      type: String,
      default: 'published',
    },
  },

  data() {
    return {
      toDelete: null,
      loading: false,
      showCard: false,
      dialogRecipe: null,
      deleteDialog: false,
      deleteMessage: null,
      allIdsDialog: false,
      showCustomCard: false,
      customDialogRecipe: null,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    TodosChip,
    RecipeCard,
    RecipeImage,
    RecipeRating,
    CustomRecipeCard,
    AllRecipeIdsDialog,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      status: (state) => state.recipes.status,
    }),

    savedRecipes () {
      return this.user.savedRecipes || [];
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', ['saveRecipe', 'unsaveRecipe']),
    ...mapActions('recipe', ['duplicateRecipe']),
    ...mapActions('recipes', ['deleteRecipe']),
    openRecipe(recipe) {
      this.$router.push({ name: 'EditRecipe', params: { id: recipe.id } });
    },
    showRecipeCard(recipe) {
      if (recipe.isCustomized && this.view != 'my') this.showCustomRecipeCard(recipe);
      else {
        this.dialogRecipe = {};
        Object.assign(this.dialogRecipe, recipe);
        this.showCard = true;
      }
    },
    showCustomRecipeCard(recipe) {
      this.dialogRecipe = {};
      Object.assign(this.dialogRecipe, recipe);
      this.showCustomCard = true;
    },
    closeRecipeCard() {
      this.showCard = false;
      this.dialogRecipe = null;
    },
    closeCustomRecipeCard() {
      this.showCustomCard = false;
      this.dialogRecipe = null;
    },
    updateToPending(recipe) {
      this.$emit('updateToPending', recipe);
    },
    setDeleteData(recipe) {
      this.deleteMessage = `Are you sure you want to delete recipe "${recipe.name}"?`
      this.deleteDialog = true
      this.toDelete = recipe
    },
    deleteConfirmed() {
      Promise.resolve(this.deleteRecipe(this.toDelete))
      .then(() => {
        this.deleteDialog = false
      })
    },
    save(data) {
      Promise.resolve(this.saveRecipe(data))
        .then(() => {
          this.loading = true
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit('recipes/insertSavedRecipe2', this.recipe)
          this.loading = false
        });
    },
    unsave(data) {
      Promise.resolve(this.unsaveRecipe(data))
        .then(() => {
          this.loading = true
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit('recipes/removeSavedRecipe', this.recipe.id)
          this.loading = false
        });
    },

    navigate(e) {
      console.log(e)
    }
  },
};
</script>
