<template>
  <div>
    <v-dialog
      :value="dialog"
      no-click-animation
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">Please Confirm</span>
        </v-card-title>
        <v-card-text>
            <div class="flex flex-column gap-10 align-center justify-center text-center mt-5 mb-8" >
                <span v-if="status.gettingAllIds">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    >
                    </v-progress-circular>
                    <p>
                        loading all Recipes
                    </p>
                </span>
                <span v-if="!status.gettingAllIds && !loading && !finish">
                    <p>{{ totalRecipes }} recipes found</p>
                    <p>Are you sure you want to update them all?</p>
                </span>
                <span v-if="loading">
                    <p>Updating {{ counter }} of {{ totalRecipes }} recipes </p>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    >
                    </v-progress-circular>
                </span>
                <span v-if="finish">
                  <p>All {{ totalRecipes }} Recipes have been updated!</p>
                </span>
                <span v-if="error">
                  <v-alert
                    color="red"
                    icon="$mdiAccount"
                    type="error"
                  >
                  There's something went wrong.
                  </v-alert>
                </span>
            </div>
          <div>
            <v-btn
              color="accent"
              class="mr-2"
              depressed
              :disabled="status.gettingAllIds || finish || loading"
              @click="updateAllRecipe()"
            >Confirm</v-btn>

            <v-btn
              v-if="!finish"
              depressed
              @click="closeDialog()"
            >Cancel</v-btn>
            <v-btn
              v-if="finish"
              depressed
              @click="closeDialog()"
            >Close</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading"></v-overlay>
  </div>
</template>

<script>
import _ from 'lodash';
import db from '@/firebase/init'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    name: 'AllRecipeIdsDialog',
    data () {
        return {
            ings: [],
            counter: 0,
            dialog: false,
            finish: false,
            loading: false,
            error: false,
            tagLargeWeight: { mainAmount: 0 },
            tagSmallWeight: { mainAmount: 100000000 },
        }
    },
    computed: {
        ...mapState({
            status: state => state.recipes.status,
            allRecipeIds: state => state.recipes.allRecipeIds,
            data: state => state.recipe.data,
            ingredients: state => state.recipe.ingredients,
            mealTagsFromExcel: state => state.excel.mealTags,
        }),

        ...mapGetters('recipes', ['totalRecipes']),

        ...mapGetters('recipe', [
          'getTotalCarbohydrate',
          'getTotalProtein',
          'getTotalWeight',
          'getTotalEnergy',
          'getTotalSodium',
          'getTotalSugar',
          'getTotalFibre',
          'getTotalFat',
        ]),

        minerals: function () {
          return [
            { name: 'Carbohydrate', value: this.getTotalCarbohydrate},
            { name: 'Protein', value: this.getTotalProtein},
            { name: 'Energy', value: this.getTotalEnergy}, 
            { name: 'Sodium', value: this.getTotalSodium},
            { name: 'Weight', value: this.getTotalWeight},
            { name: 'Sugar', value: this.getTotalSugar},
            { name: 'Fibre', value: this.getTotalFibre},
            { name: 'Fat', value: this.getTotalFat},
          ]
        },


        nutritionTags: function () {
          return this.$store.getters['nutritiontags/tags']
        },

        mealTags: function () {
          return this.$store.getters['tags/tags']
        },

        gramPerServe: function () {
          return Math.round(this.getTotalWeight)
        },

        // WEIGHT
        getTotalWeight: (state) => {
          if (state.ingredients && state.ingredients.length) {
            return Math.round(_.sumBy(state.ingredients, (ing) => { return ing.portion.weight * ing.quantity }) / state.data.numberOfServes)
          }
          else {
            return 0
          }
        },

        // ENERGY
        getTotalEnergy: (state) => {
          if (state.ingredients && state.ingredients.length) {
            return _.sumBy(state.ingredients, (ing) => { 
              return parseFloat((ing.food.energyKj && ((ing.food.energyKj.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
          }
          else {
            return 0
          }
        },
        
        // ENERGY
        getTotalProtein: (state) => {
          if (state.ingredients && state.ingredients.length) {
            return _.sumBy(state.ingredients, (ing) => { 
              return parseFloat((ing.food.protein && ((ing.food.protein.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
          }
          else {
            return 0
          }
        },
        
        // FAT
        getTotalFat: (state) => {
          if (state.ingredients && state.ingredients.length) {
            return _.sumBy(state.ingredients, (ing) => { 
              return parseFloat((ing.food.fat && ((ing.food.fat.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
          }
          else {
            return 0
          }
        },
        
        // CARBOHYDRATE
        getTotalCarbohydrate: (state) => {
          if (state.ingredients && state.ingredients.length) {
            return _.sumBy(state.ingredients, (ing) => { 
              return parseFloat((ing.food.carbohydrate && ((ing.food.carbohydrate.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
          }
          else {
            return 0
          }
        },
        
        // SUGAR
        getTotalSugar: (state) => {
          if (state.ingredients && state.ingredients.length) {
            return _.sumBy(state.ingredients, (ing) => { 
              return parseFloat((ing.food.sugars && ((ing.food.sugars.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
          }
          else {
            return 0
          }
        },
        
        // FIBRE
        getTotalFibre: (state) => {
          if (state.ingredients && state.ingredients.length) {
            return _.sumBy(state.ingredients, (ing) => { 
              return parseFloat((ing.food.fibre && ((ing.food.fibre.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
          }
          else {
            return 0
          }
        },
        
        // SODIUM
        getTotalSodium: (state) => {
          if (state.ingredients && state.ingredients.length) {
            return _.sumBy(state.ingredients, (ing) => { 
              return parseFloat((ing.food.sodium && ((ing.food.sodium.val / 100) * ing.portion.weight)) || 0) * ing.quantity }) / state.data.numberOfServes
          }
          else {
            return 0
          }
        },
    },


    methods: {
        ...mapActions('recipes', ['getAllIds', 'approveRecipe']),
        ...mapActions('recipe', ['addRecipe']),
        
        openDialog () {
            this.dialog = true
            this.finish = false;
            this.counter = 1;
            this.error = false
            this.getAllIds()
        },

        closeDialog () {
            this.dialog = false
            this.loading = false
        },


        calcPerHundredGram(value) {
          let total =  value / this.data.numberOfServes
          let weight = this.gramPerServe / 100
          total = value / weight
          return this.$options.filters.numberFormat(total ? total.toFixed(2) : 0)
        },

        checkNutritionTags() {
          if (this.data.mealTime && this.data.mealTime.length && this.ingredients && this.ingredients.length) {

            return new Promise((res) => {
              this.nutritionTags.forEach(tag => {
                if (tag.main || tag.hundred || tag.snack) {
                  this.minerals.forEach(mineral => {
                    // MAIN MEALS NUTRITION TAGS
                    if (_.intersection(['Breakfast', 'Lunch', 'Dinner'], this.data.mealTime).length && tag.main && tag.main.name == mineral.name) {
                      if (tag.main && tag.main.name == 'Weight') {
                        if (tag.mainCondition == '≤') {
                          if (
                              (parseFloat(this.tagSmallWeight.mainAmount) >= parseFloat(tag.mainAmount) && parseFloat(tag.mainAmount) >= mineral.value) ||
                              (this.tagSmallWeight.id && mineral.value > this.tagSmallWeight.mainAmount && parseFloat(tag.mainAmount) >= mineral.value)
                          ) {
                            if (this.tagSmallWeight.id) this.$store.commit('recipe/removeNutritionTag', this.tagSmallWeight.id)
                            Object.assign(this.tagSmallWeight, tag)
                            this.$store.commit('recipe/insertNutritionTag', this.tagSmallWeight.id)
                          }
                          else {
                            this.$store.commit('recipe/removeNutritionTag', tag.id)
                          }
                        } 
                        else if (tag.mainCondition == '≥') {
                          if (
                            (parseFloat(tag.mainAmount) >= parseFloat(this.tagLargeWeight.mainAmount) && mineral.value >= parseFloat(tag.mainAmount)) ||
                            (this.tagLargeWeight.id && mineral.value < this.tagLargeWeight.mainAmount && parseFloat(tag.mainAmount) <= mineral.value)
                          ) {
                            if (this.tagLargeWeight.id) this.$store.commit('recipe/removeNutritionTag', this.tagLargeWeight.id)
                            
                            Object.assign(this.tagLargeWeight, tag)
                            this.$store.commit('recipe/insertNutritionTag', this.tagLargeWeight.id)
                          }
                          else {
                            this.$store.commit('recipe/removeNutritionTag', tag.id)
                          }
                        }
                        
                      }
                      else if ((tag.mainCondition == '≤' && mineral.value <= parseFloat(tag.mainAmount)) || 
                          (tag.mainCondition == '≥' && mineral.value >= parseFloat(tag.mainAmount))) {
                        this.$store.commit('recipe/insertNutritionTag', tag.id)
                      }
                      else {
                        this.$store.commit('recipe/removeNutritionTag', tag.id)
                      }

                      // RANGE TAGS
                      if(tag.range) {

                        if(mineral.value >= parseFloat(tag.mainAmount) && mineral.value <= parseFloat(tag.secondAmount)) {
                          this.$store.commit('recipe/insertNutritionTag', tag.id)
                        }
                        else {
                          this.$store.commit('recipe/removeNutritionTag', tag.id)
                        }
                      }
                    }
                    
                    // SNACK NUTRITION TAGS
                    if (this.data.mealTime.includes('Snack') && tag.snack && tag.snack.name == mineral.name) {
                      if ((tag.snackCondition == '≤' && mineral.value <= parseFloat(tag.snackAmount)) || 
                          (tag.snackCondition == '≥' && mineral.value >= parseFloat(tag.snackAmount))) {
                        this.$store.commit('recipe/insertNutritionTag', tag.id)
                      }
                      else {
                        this.$store.commit('recipe/removeNutritionTag', tag.id)
                      }

                      if(tag.range && tag.snackAmount) {
                        if(mineral.value >= parseFloat(tag.snackAmount) && mineral.value <= parseFloat(tag.snackSecondAmount)) {
                          this.$store.commit('recipe/insertNutritionTag', tag.id)
                        }
                        else {
                          this.$store.commit('recipe/removeNutritionTag', tag.id)
                        }
                      }
                    }
                    
                    // MEAL NUTRITION PER HUNDRED GRAM
                    if (this.data.mealTime && this.data.mealTime.length && tag.hundred && tag.hundred.name == mineral.name) {
                      if ((tag.hundredCondition == '≤' && this.calcPerHundredGram(mineral.value) <= parseFloat(tag.hundredAmount)) || 
                          (tag.hundredCondition == '≥' && this.calcPerHundredGram(mineral.value) >= parseFloat(tag.hundredAmount))) {
                        this.$store.commit('recipe/insertNutritionTag', tag.id)
                      }
                      else {
                        this.$store.commit('recipe/removeNutritionTag', tag.id)
                      }

                      if(tag.range && tag.hundredAmount) {
                        if(parseFloat(this.calcPerHundredGram(mineral.value)) >= parseFloat(tag.hundredAmount) && this.calcPerHundredGram(mineral.value) <= parseFloat(tag.hundredSecondAmount)) {
                          this.$store.commit('recipe/insertNutritionTag', tag.id)
                        }
                        else {
                          this.$store.commit('recipe/removeNutritionTag', tag.id)
                        }
                      }
                    }
                  })
                }

                // NEW TAGS
                if(tag.switchToBool) {
                  // GET ALL THE TAGS
                  if(this.ingredients.length === this.data.ingredients.length) {
                    console.log('-----------NUTRITION TAGS---------')
                    let tags = this.ingredients.map(ing => ing.food[tag.mainBool])

                    console.log(tag.name)
                    console.log(tags)

                    tags = tags.filter(Boolean)

                    if(tags.every(t => t.toLowerCase() === tag.mainBoolVal.toLowerCase())){
                      this.$store.commit('recipe/insertNutritionTag', tag.id)
                    }
                    else {
                      this.$store.commit('recipe/removeNutritionTag', tag.id)
                    }
                  }
                }

              })

              res()
            })

          }
        },
    
        checkMealTags () {
          if(this.ingredients.length === this.data.ingredients.length) {
            return new Promise((res) => {
              console.log('-----------MEAL TAGS---------')
                this.mealTags.forEach(tag => {
                  if(this.mealTagsFromExcel.includes(tag.name)) {
                    let tags = this.ingredients.map(ing => ing.food[tag.name])

                    console.log(tag.name)
                    console.log(tags)

                    tags = tags.filter(Boolean)

                    if(tags.every(t => t.toLowerCase() === 'yes')) {
                      this.$store.commit('recipe/insertMealTag', tag.id)
                    } else {
                      this.$store.commit('recipe/removeMealTag', tag.id)
                    }
                  }
                })

                setTimeout(() => {
                  // 6 ingredients or less Tag
                  if(this.data.ingredients.length <= 6)
                    this.$store.commit('recipe/insertMealTag', 'cVTWVj4Rj1IwlxuNy07L')
                  else
                    this.$store.commit('recipe/removeMealTag', 'cVTWVj4Rj1IwlxuNy07L')


                  // Simple Tag
                  if(this.data.ingredients.length <= 6 && this.data.methods.length <= 6) 
                    this.$store.commit('recipe/insertMealTag', 'iTOmeAWBl7iC6y63pU3t')
                  else
                    this.$store.commit('recipe/removeMealTag', 'iTOmeAWBl7iC6y63pU3t')

                  res()
                }, 2000);
            })
          }
        },


        async backup () {
          return new Promise((res) => {
            db.collection('recipes2').doc(this.data.id)
            .set(_.omit(this.data, ['id', 'ref']))
            .then(() => {
              let batch = db.batch()
              this.ingredients.forEach( async (ing, index) => {
                let ingredient = {
                  food: ing.food.id,
                  portion: ing.portion.id || 'gram',
                  quantity: ing.quantity,
                  order: typeof ing.order === 'undefined' ? index : ing.order
                }

                let ingRef = db.collection('recipes2').doc(this.data.id).collection('ingredients').doc(ing.food.id)

                batch.set(ingRef, ingredient)
              })

              batch.commit()
              .then(() => {
                res()
              })
            })

          })
        },

        async updateAllRecipe () {
          this.loading = true;

          for (let recipe of this.allRecipeIds) {
            let recipeCopy = Object.assign({}, recipe)
            let data = recipeCopy

            await this.$store.commit('recipe/resetState')
            await this.$store.dispatch('recipe/getRecipe', recipe.id)


            // await this.backup()

             data.ingredients = this.ingredients.map((ing, index) => {
              return {
                food: ing.food.name,
                portion: ing.portion.name,
                quantity: ing.quantity,
                order: typeof ing.order === 'undefined' ? index : ing.order
              }
            })


            await this.checkNutritionTags()
            await this.checkMealTags()

            await this.UpdateRecipe()

          }
          
          this.loading = false
          this.finish = true
        },

        UpdateRecipe() {
          new Promise((res) => {
            this.$store.commit('recipe/updateNutritionValues', {
              serving: {
                weight: parseFloat(this.getTotalWeight),
                energy: parseFloat(this.getTotalEnergy),
                protein: parseFloat(this.getTotalProtein),
                fat: parseFloat(this.getTotalFat),
                carbohydrate: parseFloat(this.getTotalCarbohydrate),
                sugar: parseFloat(this.getTotalSugar),
                fibre: parseFloat(this.getTotalFibre),
                sodium: parseFloat(this.getTotalSodium)
              },
              hundredGram: {
                energy: parseFloat(this.calcPerHundredGram(this.getTotalEnergy)),
                protein: parseFloat(this.calcPerHundredGram(this.getTotalProtein)),
                fat: parseFloat(this.calcPerHundredGram(this.getTotalFat)),
                carbohydrate: parseFloat(this.calcPerHundredGram(this.getTotalCarbohydrate)),
                sugar: parseFloat(this.calcPerHundredGram(this.getTotalSugar)),
                fibre: parseFloat(this.calcPerHundredGram(this.getTotalFibre)),
                sodium: parseFloat(this.calcPerHundredGram(this.getTotalSodium))
              }
            })

            this.backup()
            .then(() => {
              console.log('updating')
              console.log('updated')
              this.counter++
              res()
            })

            // Promise.resolve(this.addRecipe(true))
            // .then(() => {
            //   console.log('updating')
            //   console.log('updated')
            //   this.counter++
            //   res()
            // })
          })
        },
    },

    mounted() {
      if (!this.$store.state.tags.status.firstLoad) this.$store.dispatch('tags/getTags')
      if (!this.$store.state.nutritiontags.status.firstLoad) this.$store.dispatch('nutritiontags/getTags')
    }
}
</script>
