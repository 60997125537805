<template>
  <div class="todo-chip">
    <v-chip 
      @click.prevent="$emit('showRecipe')" 
      v-if="pendings.length" 
      color="warning" 
      x-small
      link
    >
      <v-icon x-small left>mdi-alert-circle</v-icon>

      <span>{{ pendings.length }}</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    recipe: {
      type: Object,
      required: true
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      todos: []
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    pendings: function () {
      return this.todos.filter(todo => {
        return !todo.done
      })
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getTodos() {
      if (this.recipe) {
        this.recipe.ref
        .collection('todos')
        .get()
        .then((snapshot) => {
          if (snapshot.size) {
            snapshot.forEach(doc => {
              let todo = doc.data()
              todo.id = doc.id
              todo.ref = doc.ref
              this.todos.push(todo)
            })
          }
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getTodos()
  }
}
</script>

<style lang="scss">

.todo-chip {
  position: absolute;
  right: 0px;
  bottom: 5px;
}

</style>